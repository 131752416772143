export const BASE_URL =
    process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_DEV_MODE
        : process.env.REACT_APP_PRO_MODE;

export const IMPERSONATION_LINK = process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_ADMIN_DEV_MODE
    : process.env.REACT_APP_ADMIN_PRO_MODE;

// INFLUENCER

export const AUTH_INFLUENCER = BASE_URL + 'auth/influencer';

export const INFLUENCER_EXPLORE_CAMPAIGNS = BASE_URL + 'influencer/explore';
export const INFLUENCER_PLANNER = BASE_URL + 'influencer/planner';

export const INFLUENCER_OFFER_MANAGEMENT = BASE_URL + 'influencer/offer/management';

export const INFLUENCER_OFFER_MODAL = BASE_URL + 'influencer/offer/modal';

export const INFLUENCER_INITIAL_OFFER = BASE_URL + 'influencer/offer/initial';

export const INFLUENCER_OFFER = BASE_URL + 'influencer/offer';

export const INFLUENCER_INVOICE = BASE_URL + 'influencer-invoice';

export const DOWNLOAD_INVOICE = BASE_URL + 'admin/invoicing/download';

export const INFLUENCER_DASHBOARD = BASE_URL + 'influencer/dashboard';

export const INFLUENCER_MY_CAMPAIGNS = BASE_URL + 'influencer/my-campaigns';

export const INFLUENCER_BRIEFING = BASE_URL + 'influencer/briefing';

export const INFLUENCER_REPORTING = BASE_URL + 'influencer/reporting';

export const INFLUENCER_SOCIAL_AUTH_MANUAL = BASE_URL + 'manual/authorize';

export const INFLUENCER_UPDATE_SOCIAL_MANUAL = BASE_URL + 'manual/restricted/authorize';

export const INFLUENCER_SOCIAL_AUTH = BASE_URL + 'authorize';

export const INFLUENCER_SOCIAL_DELETE = BASE_URL + 'social-platform';
// BRAND

export const BRAND_DASHBOARD_URL = BASE_URL + 'brand/dashboard';

export const BRAND_CLIENT = BASE_URL + 'brand/client';

export const BRAND_DASHBOARD_BOOKING = BASE_URL + `brand/dashboard/booking`;

export const BRAND_DASHBOARD_MEASURE = BASE_URL + `brand/dashboard/measure`;

export const BRAND_DASHBOARD_MANAGEMENT =
    BASE_URL + `brand/dashboard/management`;

export const BRAND_DASHBOARD_BRANDS_URL = BASE_URL + 'brand/dashboard/brands';

export const LOGIN_URL = BASE_URL + 'auth/login';

export const AUTH_BRAND_URL = BASE_URL + 'auth/brand';

export const AUTH_AGENCY_URL = BASE_URL + 'auth/agency';
export const AGENCY_INVOICE = BASE_URL + 'influencer-invoice/agency';

export const AUTH_REGISTER_STEP_URL = BASE_URL + 'auth/register/step';

export const AUTH_RESET_PASSWORD = BASE_URL + 'auth/password/reset';

export const AUTH_ME = BASE_URL + 'auth/me';

export const VERIFY_2FA_URL = BASE_URL + 'auth/2fa/verify';
export const VERIFY_2FA_RESEND_URL = BASE_URL + 'auth/2fa/resend/code';


export const AUTH_TERMS = BASE_URL + 'auth/terms/notification';

export const ENUMS_URL = BASE_URL + 'enums';

export const ASSIGN_USER_URL = BASE_URL + 'brand/dashboard/assign/user';

export const UN_ASSIGN_USER_URL = BASE_URL + 'brand/dashboard/un-assign/user';

export const BRAND_MANAGING_CAMPAIGN = BASE_URL + 'brand/dashboard/managing/campaign';

export const PACKAGES_URL = BASE_URL + 'admin/packages';

// PROFILE
export const CHANGE_PASSWORD_URL = BASE_URL + 'profile/change-password';

export const GLOBAL_PREFERENCE_URL = BASE_URL + 'profile/global-preference';

export const PERSONAL_INFO_URL = BASE_URL + 'profile/personal-info';

export const DELETE_USER_PROFILE_URL = BASE_URL + 'admin/users/delete';

export const PROFILE_COMPANY_URL = BASE_URL + 'profile/company';

export const AGENCY_COMPANY_URL = BASE_URL + 'profile/agency/company';

export const AGENCY_COMPANY_BILLING_URL = BASE_URL + 'profile/agency/company-billing';

export const PROFILE_INFLUENCER_URL = BASE_URL + 'profile/influencer';

export const PROFILE_COMPANY_BILLING_URL = BASE_URL + 'profile/company-billing';

export const PROFILE_BILLING_INFO_URL = BASE_URL + 'profile/billing-info';

export const PROFILE_COMPANY_USERS_URL = BASE_URL + 'brand/company/users';

export const PROFILE_CREATE_COMPANY_USER_URL = BASE_URL + 'brand/company/user';

export const PROFILE_PRIVATE_COMMENT_URL = BASE_URL + 'profile/private/comment/offer';

export const PROFILE_SEND_COMMENT_URL = BASE_URL + 'profile/send/private/comment';

export const PRICE_LIST_URL = BASE_URL + 'influencer/price-list';

export const RIGHTS_AND_EXCLUSIVITY_URL =
    BASE_URL + 'influencer/usage-rights/exclusivity';

export const INFLUENCER_PROFILE = BASE_URL + 'influencer/profile';

export const CHECK_DISPLAY_NAME = BASE_URL + 'profile/check/displayName';

export const CHECK_EMAIL = BASE_URL + 'profile/check/email';

// CAMPAIGN
export const FAVORITE_URL = BASE_URL + 'favorite';

export const CATEGORY_URL = BASE_URL + 'category';

export const BRAND_CAMPAIGN = BASE_URL + 'brand/dashboard/campaign';

export const BRAND_CAMPAIGNS = BASE_URL + 'brand/dashboard/campaigns';

export const BRAND_SUPERVISORS = BASE_URL + 'brand/dashboard/supervisors';

// BOOK
export const BRAND_BOOKING_URL = BASE_URL + 'brand/booking';

export const BRAND_BOOKING_OFFER_DECLINE_URL = BASE_URL + 'brand/booking/offers/decline';

export const BRAND_BOOKING_OFFER_ACCEPT_URL = BASE_URL + 'brand/booking/offers/accept';

export const BRAND_BOOKING_OFFER_SELECT_URL = BASE_URL + 'brand/booking/offers/selection';

export const BRAND_BOOKING_OFFER_CART_URL = BASE_URL + 'brand/booking/offers/cart';

export const BRAND_BOOKING_OFFER_CHECKOUT_URL = BASE_URL + 'brand/booking/offers/checkout';

export const BRAND_BOOKING_MODALS = BASE_URL + 'brand/booking/modals';

export const BRAND_BOOKING_INVITE_USER = BASE_URL + 'brand/booking/invites/user';

export const BRAND_BOOKING_EXTERNAL_SEARCH = BASE_URL + 'brand/booking/external-search';

export const PLATFORM_URL = BASE_URL + 'admin/platform';

// BUNDLE / OPTIONS
export const BUNDLE_URL = BASE_URL + 'brand/bundle'

// MANAGE

export const MANAGE_URL = BASE_URL + 'brand/manage';

// MEASURE

export const MEASURE_URL = BASE_URL + 'brand/measure';

export const MEASURE_PLATFORMS = BASE_URL + 'brand/measure/platforms';

export const MEASURE_PLATFORMS_DATA = BASE_URL + 'brand/measure/report/platform';

export const MEASURE_REPORT_POST = BASE_URL + 'brand/measure/report/post/modal';

export const MEASURE_INFLUENCERS = BASE_URL + 'brand/measure/influencers';

export const MEASURE_INFLUENCERS_DATA = BASE_URL + 'brand/measure/report/influencers';

export const MEASURE_REVIEW = BASE_URL + 'brand/measure/review';

export const MEASURE_DOWNLOAD = BASE_URL + 'brand/measure/download';

// MESSAGES

export const MESSAGES_URL = BASE_URL + 'messages';

export const MESSAGE_DOWNLOAD_FILES_URL = BASE_URL + 'messages/message/media/download';

// BRIEFING

export const BRAND_BRIEFING = BASE_URL + 'brand/briefing';

export const BRIEFING_URL_MODAL = BASE_URL + 'brand/briefing/modal';

export const BRIEFING_LONG_TERM = BASE_URL + 'brand/company/long-term';

// AGENCY

export const AGENCY_URL = BASE_URL + 'admin/agency';

export const AGENCY_DASH_URL = BASE_URL + 'admin/agency/dashboard';

export const IMPERSONATE_URL = BASE_URL + 'admin/users';

export const AGENCY_USER_URL = BASE_URL + 'admin/agency/company/user';
export const AGENCY_PLANNER = BASE_URL + 'admin/agency';
export const AGENCY_REPORTING = BASE_URL + 'admin/agency/reporting';

