import React, {useEffect, useRef, useState} from 'react';
import {Card, Col, OverlayTrigger, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {User} from '../../../../../../model/user/User';
import {CustomImageHandler} from '../../../../../../shared/components/CustomImageHandler';
import MentionInputField from '../../../../../../shared/components/message/MentionInputField';
import {ManageService} from '../../../manage/service';
import {BriefingService} from '../../service';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {IParams} from '../../../../../../model/shared/IParams';
import {ErrorToast, WarningToast} from '../../../../../../utils/toasters';
import {
    formatDate,
    getFirstPreviewImage,
    getStatusIcon,
    hasAccessRights,
} from '../../../../../../shared/functions/Functions';
import {ICampaignOverview} from '../../../../../../model/briefing/overview/ICampaignOverview';
import Tooltip from '@mui/material/Tooltip';
import {priceSplitter} from '../../../../../../utils/helpers/priceSplitter';
import {FaCog} from 'react-icons/fa';
import {MessageBody} from '../../../../../../shared/components/message/MessageBody';
import {iManageMessage} from '../../../manage/calendar/dto/IManageCalendar';
import {useDispatch, useSelector} from 'react-redux';
import {IAllStates} from '../../../../../../store/rootReducer';
import {MdSend} from 'react-icons/md';
import {IBriefingCommentRequest} from '../../dto/IBriefingCommentRequest';
import {AccessRightEnum} from '../../../../../../enum/AccessRightEnum';
import {selectedCampaignSlice} from '../../../../../../store/brand/activeCampaignSlice';
import {FindCreatorsInfo} from './FindCreatorsInfo';
import HtmlToPdf from '../../../../../../shared/components/generate-pdf';
import {LinkedCampaignsCard} from './LinkedCampaignsCard';

interface ICampaignOverviewCardProps {
    triggerUpdate?: boolean;
    openEditCampaignModal?: (editContractType?: boolean) => void;
}

export function CampaignOverviewCard({triggerUpdate, openEditCampaignModal}: ICampaignOverviewCardProps) {
    const {id} = useParams<IParams>();
    const dispatch = useDispatch();
    dispatch(selectedCampaignSlice.actions.setActiveCampaignId(id));
    const [currentInformation, setCurrentInformation] = useState<ICampaignOverview>();
    const [comments, setComments] = useState<iManageMessage[]>([]);
    const [customerSuccessSuggestions, setCustomerSuccessSuggestions] = useState<User[]>([])
    const [briefingSuggestions, setBriefingSuggestions] = useState<User[]>([])
    const [briefingComments, setBriefingComments] = useState<iManageMessage[]>([]);
    const [isSendDisabled, setIsSendDisabled] = useState(false)
    const [newMessage, setNewMessage] = useState<IBriefingCommentRequest>({
        message: '',
        campaignId: +id,
    });
    const [newBriefingMessage, setNewBriefingMessage] = useState<IBriefingCommentRequest>({
        message: '',
        campaignId: +id,
    });
    const {user} = useSelector((states: IAllStates) => states.auth);
    const history = useHistory();
    const {t} = useTranslation();
    const lastMessageRef = useRef<HTMLInputElement>(null);
    const lastBriefingMessageRef = useRef<HTMLInputElement>(null);
    const location = useLocation();
    const {accessRights} = useSelector((state: IAllStates) => state.auth.user);
    const canSeeBriefingFeedback = accessRights.includes(AccessRightEnum.CAN_VIEW_BRIEFING_CHAT);
    const influencerFeedbackHide = accessRights.includes(AccessRightEnum.INFLUENCER_FEEDBACK_HIDE);
    const isMobile = window.innerWidth <= 600;

    useEffect(() => {
        !!+id && BriefingService.getBriefingByStep(+id, 'overview').then(response => {
            if (response?.isDeleted) {
                goToOverview();
            }
            setCurrentInformation(response);
        }).catch(error => ErrorToast(error));
        // eslint-disable-next-line
    }, [location?.pathname, id, triggerUpdate]);

    useEffect(() => {
        BriefingService.getCustomerSuccessComment(+id, {perPage: 0})
            .then((response) => {
                setComments(response?.data);
            });
    }, []);
    useEffect(() => {
        if (comments?.length && lastMessageRef?.current) {
            lastMessageRef?.current?.scrollIntoView({
                behavior: 'auto',
                block: 'nearest',
            });
        }
    }, [comments?.length, lastMessageRef?.current]);

    useEffect(() => {
        if (briefingComments?.length && lastBriefingMessageRef?.current) {
            lastBriefingMessageRef?.current?.scrollIntoView({
                behavior: 'auto',
                block: 'nearest',
            });
        }
    }, [briefingComments?.length, lastBriefingMessageRef?.current]);

    useEffect(() => {
        BriefingService.getBriefingComment(+id, {perPage: 0})
            .then((response) => {
                setBriefingComments(response?.data);
            });
    }, []);

    const sendMessageHandler = () => {
        if (!newMessage?.message?.trim()?.length) {
            return WarningToast(t('general.toasts.warnEmptyMessage'));
        }
        setIsSendDisabled(true);
        BriefingService.postCustomerSuccessComment({
            ...newMessage,
            message: newMessage.message.replaceAll('\n', '</br>')
        })
            .then((response) => {
                setComments(prevState => prevState.concat(response));
                setNewMessage({...newMessage, message: ''});
            }).catch(error => ErrorToast(error)).finally(() => setIsSendDisabled(false));
    };

    const sendBriefingMessageHandler = () => {
        if (!newBriefingMessage?.message?.trim()?.length) {
            return WarningToast(t('general.toasts.warnEmptyMessage'));
        }
        setIsSendDisabled(true);
        BriefingService.postBriefingComment({
            ...newBriefingMessage,
            message: newBriefingMessage.message.replaceAll('\n', '</br>')
        }).then((response) => {
            setNewBriefingMessage({...newBriefingMessage, message: ''});
            setBriefingComments(prevState => prevState.concat(response));
        }).catch(error => ErrorToast(error)).finally(() => setIsSendDisabled(false));
    };

    const sendBriefingMessagePressingEnter = (
        event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        if (event.key === 'Enter') {
            return sendBriefingMessageHandler();
        }
    };

    const changeMessageHandler = (name: string, value: string) => {
        setNewMessage({...newMessage, [name]: value});
    };

    const changeHandlerBriefing = (name: string, value: string) => {
        setNewBriefingMessage({...newBriefingMessage, [name]: value});
    };

    const goToOverview = () => {
        history.push(`/brand/create/campaign/${id}/overview`);
    };

    useEffect(() => {
        if (!!currentInformation?.id) {
            ManageService.getMentionParticipants(currentInformation?.id, false).then(response => {
                setCustomerSuccessSuggestions(response?.data)
            }).catch(err => ErrorToast(err));
            ManageService.getMentionParticipants(currentInformation?.id, true, true).then(response => {
                setBriefingSuggestions(response?.data)
            }).catch(err => ErrorToast(err));
        }
    }, [currentInformation?.id])

    return (
        <div className="position-relative">
            {currentInformation?.status &&
                <div className={`offer-status-badge mt-4 ${currentInformation?.status} z-index-2`}>
                    {getStatusIcon(currentInformation?.status ?? '', 'rotate--120 text-white')}
                    <p>{t(`general.campaign_status.${currentInformation?.status}`)}</p>
                </div>}
            <Card className="mb-4 mt-4 mt-md-0 overflow-hidden">
                <Card.Body className="px-0 p-md-3">
                    <Row>
                        <Col md={6} className="pl-md-0 ">
                            {getFirstPreviewImage('style_uploaded_image', currentInformation?.campaignImages)}
                            {isMobile && currentInformation?.approvalStatus &&
                                <span
                                    className={` place-top-right status w-max-content ${currentInformation?.approvalStatus}`}>
                                {currentInformation?.approvalStatus ? currentInformation?.approvalStatus : '-'}
                            </span>}
                        </Col>
                        <Col md={6}
                             className="d-flex flex-column align-items-md-start justify-content-between px-md-0">
                            <div
                                className={`d-flex ${isMobile && 'align-items-center justify-content-between'} overflow-auto w-100`}>
                                <h5 className="mb-0 mt-3 mt-md-0 font-weight-bold pr-3">
                                    {currentInformation?.title ? currentInformation?.title : '-'}
                                </h5>
                                {!!openEditCampaignModal && (
                                    <FaCog className={`cursor-pointer ${!isMobile && 'position-absolute'}`}
                                           style={{right: 0}}
                                           id="edit-icon"
                                           name="modal"
                                           onClick={() => openEditCampaignModal && (!currentInformation?.isDeleted && openEditCampaignModal())}
                                    />
                                )}
                            </div>
                            {currentInformation?.approvalStatus &&
                                <span
                                    className={`mt-3 status w-max-content ${currentInformation?.approvalStatus} d-none d-md-block`}>
                                {currentInformation?.approvalStatus ? currentInformation?.approvalStatus : '-'}
                            </span>}
                            <div
                                className="d-flex align-items-end mb-3 mb-md-0 d-md-block mt-3 text-center text-md-left">
                                <div className="col-6 col-md-12 pl-0">
                                    <p
                                        className="mb-0 text-primary text-center text-md-left font-weight-semi-bold">
                                        {currentInformation?.date ? formatDate(currentInformation?.date as string) : 'DD.MM.YYYY.'}</p>
                                    <p className="mb-0"> {t('brand.campaign.briefing.application_deadline')}</p>
                                </div>
                                <div className="col-6 col-md-12 pl-md-0 pr-0 mt-md-3">
                                    <p className="mb-0 text-primary font-weight-semi-bold">{currentInformation?.publishFrom ?
                                        formatDate(currentInformation?.publishFrom as string) : 'DD.MM.YYYY.'} -
                                        {currentInformation?.publishTo ? formatDate(currentInformation?.publishTo as string) : ' DD.MM.YYYY.'}
                                    </p>
                                    <p className="mb-0">{t('brand.campaign.briefing.publication_period')}</p>
                                </div>
                            </div>
                        </Col>

                        <Row className="align-items-end mt-md-4" style={{rowGap: '10px'}}>
                            <Col xs={6} md={4} className="px-md-0 text-center">
                                <h6 className="font-weight-bold mb-0 text-primary">
                                    {currentInformation?.campaignGoal?.title ? currentInformation?.campaignGoal?.title : '-'}
                                </h6>
                                <p className="my-2">
                                    {t('brand.campaign.briefing.campaign_goal')}
                                </p>
                            </Col>
                            <Col xs={6} md={4} className="px-md-0 text-center">
                                <h6 className="font-weight-bold mb-0 text-primary">
                                    {currentInformation?.creatorType?.title ? currentInformation?.creatorType?.title : '-'}
                                </h6>
                                <p className="my-2 text-center">
                                    {t('brand.campaign.briefing.overview_card.content_creator')}
                                </p>
                            </Col>
                            <Col className="px-md-0 text-center">
                                {currentInformation?.isCharity || !hasAccessRights(AccessRightEnum.CAN_EXPORT_MEASURE) ?
                                    <p className="my-2"></p> :
                                    <>
                                        <h6 className="font-weight-bold mb-0 euro-symbol text-primary">
                                            {priceSplitter(Math.round(currentInformation?.creatorType?.budgetRanges?.[0]?.recommendedMinBudget ?? 0))}
                                            - {priceSplitter(Math.round(currentInformation?.creatorType?.budgetRanges?.[0]?.recommendedMaxBudget ?? 0))}
                                        </h6>
                                        <p className="my-2 text-center">
                                            {t('brand.campaign.briefing.overview_card.budget_range')}
                                        </p>
                                    </>
                                }
                            </Col>

                            <Col xs={6} md={4} className="my-md-2 px-md-0 d-flex flex-column align-items-center">
                                <div className="d-flex align-items-center">
                                    {currentInformation?.mainPlatforms?.length ? currentInformation?.mainPlatforms?.slice(0, 2)?.map(platform => (
                                        <div className="mx-1" key={platform?.id}>
                                            <CustomImageHandler
                                                photoPath={platform?.path as string}
                                                altTag={`${platform?.id}`}/>
                                        </div>
                                    )) : '-'}
                                    {(currentInformation?.mainPlatforms?.length ?? 0) > 2 &&
                                        <Tooltip id="white-tooltip"
                                                 title={<>
                                                     {currentInformation?.mainPlatforms?.slice(2)?.map(platform => (
                                                         <div className="mx-1 my-2" key={platform?.id}>
                                                             <CustomImageHandler
                                                                 photoPath={platform?.path as string}
                                                                 altTag={`${platform?.id}`}/>
                                                         </div>
                                                     ))}
                                                 </>} placement="right" className="cursor-pointer">
                                                <span className="icon-round bg-dark font-weight-semi-bold"
                                                      style={{width: 33}}>
                                                    + {(currentInformation?.mainPlatforms?.length ?? 0) - 2}
                                                </span>
                                        </Tooltip>
                                    }
                                </div>

                                <p className="my-2 text-center">
                                    {t('brand.campaign.briefing.overview_card.platforms')}
                                </p>
                            </Col>
                            <Col xs={6} md={4} className="my-2 px-md-0 text-center">
                                <h6 className="font-weight-bold mb-0 text-primary">150k-350k</h6>
                                <p className="my-2 text-center">
                                    {t('brand.campaign.briefing.overview_card.estimated_reach')}
                                </p>
                            </Col>
                            <Col xs={6} md={4} className="mt-0 mb-2 px-md-0 text-center">
                                <h6 className="font-weight-bold mb-0 d-flex flex-column text-center text-primary">
                                    {currentInformation?.brandedContent?.id ? 'Branded Content' : ''}
                                    {(!currentInformation?.brandedContent?.id && !currentInformation?.usageRights?.id) ? '-' : ''}
                                    <br/>
                                    {currentInformation?.usageRights?.id ? 'Usage rights' : ''}
                                </h6>
                                <p className="my-2 text-center">
                                    {t('brand.campaign.briefing.overview_card.legal')}
                                </p>
                            </Col>
                        </Row>
                        <HtmlToPdf styleContent='w-100 px-3 px-md-0' isBriefing/>
                    </Row>
                    {(currentInformation?.isLongTerm || !!currentInformation?.parent?.id) &&
                        <div className="diagonal-text">
                            <span>
                                {t(`brand.campaign.briefing.overview_card.${currentInformation?.isLongTerm ? 'long_term' : 'linked'}`)}
                            </span>
                        </div>
                    }
                </Card.Body>
            </Card>
            {!!currentInformation?.children?.length &&
                <div style={{maxHeight: '400px'}} className="mb-4 mt-4 mt-md-0 overflow-auto">
                    <h6>
                        {t('brand.campaign.briefing.overview_card.linked_campaigns')}
                    </h6>
                    <hr className="default-border-lighter"/>
                    {currentInformation?.children?.map((campaign) => (
                        <LinkedCampaignsCard campaign={campaign}/>
                    ))}
                </div>
            }
            {/*Customer success comments*/}
            {currentInformation?.hasSupervisor && !influencerFeedbackHide &&
                <Card className="mb-4 mt-4 mt-md-0">
                    <Card.Body>
                        <h5 className="mb-4 border-bottom pb-2">Customer Success Feedback</h5>
                        <div style={{maxHeight: '300px'}} className="overflow-auto pr-2">
                            {!!comments?.length ? comments?.map((comment) => (
                                <MessageBody comment={comment as any} key={comment?.id} haveFiles={false}
                                             senderData={comment?.sentBy}
                                             isMyMessage={+comment?.sentBy?.id === +user?.id}/>
                            )) : <p>No comments yet.</p>}
                            <span ref={lastMessageRef}/>
                        </div>
                        <div className="border-top d-flex align-items-center pb-2 mt-2 pt-2 w-100">
                            <CustomImageHandler
                                altTag="My profile logo"
                                thumbnailPath={user?.profilePhoto?.thumbnail as string}
                                photoPath={user?.profilePhoto?.path as string}
                                classes="user-img rounded-circle"
                            />
                            <div className="w-90 ml-1">
                                <MentionInputField value={newMessage?.message ?? ''}
                                                   possibleMentions={customerSuccessSuggestions}
                                                   changeMessageHandler={(e) => {
                                                       changeMessageHandler('message', e.target.value)
                                                   }} sendMessage={sendMessageHandler}/>
                            </div>
                            <MdSend className={`chat-input-icon ml-2 send ${isSendDisabled ? 'opacity-25' : ''}`}
                                    onClick={() => {
                                        !isSendDisabled && sendMessageHandler()
                                    }}/>
                        </div>
                    </Card.Body>
                </Card>
            }
            {/*Briefing comments*/}
            {canSeeBriefingFeedback &&
                <Card className="mb-3">
                    <Card.Body>
                        <h5 className="mb-4 border-bottom pb-2">Briefing Feedback</h5>
                        <div style={{maxHeight: '300px'}} className="overflow-auto pr-2">
                            {!!briefingComments?.length ? briefingComments?.map((comment) => (
                                <MessageBody comment={comment as any} key={comment?.id} haveFiles={false}
                                             senderData={comment?.sentBy}
                                             isMyMessage={+comment?.sentBy?.id === +user?.id}/>
                            )) : <p>No comments yet.</p>}
                            <span ref={lastBriefingMessageRef}/>
                        </div>
                        <div className="border-top d-flex align-items-center pb-2 mt-2 pt-2 w-100">
                            <CustomImageHandler
                                altTag="My profile logo"
                                thumbnailPath={user?.profilePhoto?.thumbnail as string}
                                photoPath={user?.profilePhoto?.path as string}
                                classes="user-img rounded-circle"
                            />

                            <div className="w-90 ml-1">
                                <MentionInputField value={newBriefingMessage?.message ?? ''}
                                                   possibleMentions={briefingSuggestions}
                                                   changeMessageHandler={(e) => {
                                                       changeHandlerBriefing('message', e.target.value)
                                                   }} sendMessage={sendBriefingMessagePressingEnter}/>
                            </div>
                            <MdSend className={`chat-input-icon ml-2 send ${isSendDisabled ? 'opacity-25' : ''}`}
                                    onClick={() => {
                                        !isSendDisabled && sendBriefingMessageHandler()
                                    }}/>
                        </div>
                    </Card.Body>
                </Card>
            }
            <div className="d-none d-md-block">
                <FindCreatorsInfo/>
            </div>
        </div>
    );
}
