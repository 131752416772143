import {Campaign} from '../../../../../../model/campaign/Campaign';
import {CustomImageHandler} from '../../../../../../shared/components/CustomImageHandler';
import {formatDate} from '../../../../../../shared/functions/Functions';
import {Button, Card} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {InternalRoutes} from '../../../../../../enum/InternalRoutes';

interface ILinkedCampaignsCardProps {
    campaign: Campaign;
}
export function LinkedCampaignsCard({campaign}: ILinkedCampaignsCardProps) {
    const {t} = useTranslation();
    const history = useHistory();
    const openNewCampaignAction = () => {
        history.push(
            `${InternalRoutes.BRAND_CAMPAIGN_CREATE}/${campaign.id}/overview`,
        );
    }
    return (
        <>
            <Card className="mb-2">
                <Card.Body className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                        <CustomImageHandler classes="user-img-rounded mr-2"
                                            photoPath={campaign?.campaignImages?.[0]?.thumbnail}
                                            thumbnailPath={campaign?.campaignImages?.[0]?.thumbnail}
                                            altTag="User Profile"/>
                        <div>
                            <h6 className="mb-0">{campaign?.title ?? ''}</h6>
                            <p className="mb-0 text-primary">
                                {campaign?.publishFrom ?
                                    formatDate(campaign?.publishFrom as string) : 'DD.MM.YYYY.'} -
                                {campaign?.publishTo ? formatDate(campaign?.publishTo as string) : ' DD.MM.YYYY.'}
                            </p>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <Button onClick={openNewCampaignAction} variant="outline-primary">
                            {t('general.button.open')}
                        </Button>
                    </div>
                </Card.Body>
            </Card>

        </>
    )
}