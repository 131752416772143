import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import moment from 'moment';
import React, {SetStateAction, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {BsThreeDotsVertical} from 'react-icons/bs';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {AuthService} from '../../../authentication/service';
import {UserRoles} from '../../../enum/UserRoles';
import {ENotificationType, IUserNotification} from '../../../model/campaign/Campaign';
import {redDotsSlice} from '../../../store/influencer/redDotsSlice';
import {IAllStates} from '../../../store/rootReducer';
import {ErrorToast} from '../../../utils/toasters';
import {IconBook, IconMeasure} from '../../Icons';

interface IProps {
    notification: IUserNotification;
    setFilterParams: React.Dispatch<SetStateAction<any>>;
    setIsNotificationsOpened?: React.Dispatch<SetStateAction<boolean>>;
    isHeader?: boolean;
}

const ITEM_HEIGHT = 48;

const getCorrectNotificationIcon = (notificationKey: ENotificationType) => {
    if ([ENotificationType.OFFER].includes(notificationKey)) {
        return <IconBook color={'#9ea5ab'} width={'25'} height={'25'}/>
    }
    // if (notificationKey === 'planner') {
    //     return <IconManage color={'#9ea5ab'} width={'25'} height={'25'}/>
    // }
    return <IconMeasure color={'#9ea5ab'} width={'25'} height={'25'}/>
}
const NotificationItem = ({notification, setFilterParams, setIsNotificationsOpened, isHeader = false}: IProps) => {
    const {redDots} = useSelector((state: IAllStates) => state.redDots);
    const {t} = useTranslation();
    const isSeen = notification?.isViewed;
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const {user: {userType}} = useSelector((state: IAllStates) => state.auth);
    const isInfluencer = userType === UserRoles.INFLUENCER;
    const history = useHistory();
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClickNotification = async (notificationItem: IUserNotification) => {

        await AuthService.markNotification(notificationItem?.id)
            .then(response => {
                if (response) {
                    if (!notificationItem?.isViewed) { // reduce bell counter if it's not read
                        dispatch(redDotsSlice.actions.setRedDots({
                            ...redDots,
                            notifications: Number(redDots?.notifications) - 1
                        }))
                    }
                    setFilterParams((prev: any) => ({...prev, updatedAt: new Date()?.getTime()}))
                }
            }).catch(err => ErrorToast(err));
    }
    const handleClose = (currNotification: IUserNotification, option?: string) => {
        return async () => {
            if (option === 'markAsRead' || option === 'markAsUnread') {
                await handleClickNotification(currNotification);
            } else if (option === 'open') {
                await onNotificationClick(currNotification);
            }
            setAnchorEl(null);
        };
    };

    const onNotificationClick = async (currNotification: IUserNotification) => {
        if (!currNotification?.isViewed) {
            await AuthService.markNotificationAsRead(currNotification?.id); // set as read if unread
            dispatch(redDotsSlice.actions.setRedDots({
                ...redDots,
                notifications: Number(redDots?.notifications) - 1
            }))
        }
        const paramId = Number(currNotification?.param);
        const campaignId = Number(currNotification?.campaignId);
        if (campaignId && (notification?.type === ENotificationType.OFFER)) {
            history.push(isInfluencer ? `/influencer/offers?campaignId=${campaignId}` : '')
        }
        if (paramId && (notification?.type === ENotificationType.PLANNER)) {
            history.push(isInfluencer ? `/influencer/planner?eventId=${paramId}` : '')
        }
        setIsNotificationsOpened && setIsNotificationsOpened(false);
    }
    return (
        <div className={`position-relative mb-1 item ${!isSeen ? 'unseen' : ''}`}
             onClick={() => onNotificationClick(notification)}>
            {!isSeen && <div className="red-dot position-absolute z-index-1"/>}
            <div className="d-flex align-items-start">
                <div className="p-2 bg-white w-fit-content rounded-circle box-shadow aspect-ratio-1 notification-icon">
                    <div className="h-100 d-flex align-items-center justify-content-center">
                        {getCorrectNotificationIcon(notification?.type)}
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-between w-100 ml-2">
                    <div>
                        <h6 className={'mb-0 white-space-break-spaces'}>
                            {t(`notification.${notification?.translateKey}`)}
                        </h6>
                        <div className="text-muted white-space-break-spaces">
                            {t(`notification.${isHeader ? notification.translateBody : notification?.translateBodyLong}`)}
                            {!isHeader && <span className={'ml-1'}>
                                - {moment(notification?.createdAt)?.fromNow()}
                            </span>}
                        </div>
                    </div>
                    <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? 'long-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={(e) => {
                            e.stopPropagation(); // Prevents onNotificationClick from being triggered
                            handleClick(e);
                        }}
                    >

                        <BsThreeDotsVertical className="font-16"/>
                    </IconButton>

                    <Menu
                        id="long-menu"
                        MenuListProps={{
                            'aria-labelledby': 'long-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose(notification)}
                        PaperProps={{
                            style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: 150,
                            },
                        }}
                    >
                        {[!isSeen ? 'markAsRead' : 'markAsUnread', 'open'].map((option) => {
                            return (
                                <MenuItem
                                    key={option}
                                    onClick={(e) => {
                                        e.stopPropagation(); // Prevents onNotificationClick from being triggered
                                        handleClose(notification, option)();
                                    }}
                                >
                                    <span>{t(`notification.${option}`)}</span>
                                </MenuItem>
                            );
                        })}
                    </Menu>
                </div>
            </div>
        </div>
    );
};


export default NotificationItem;
