import React, {useEffect, useState} from 'react';
import {Button, Col, Dropdown, Image, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {BiBell} from 'react-icons/bi';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {CampaignCreateModal} from '../../../../app/brand/campaign/briefing/modal/CampaignCreateModal';
import {ExitImpersonation} from '../../../../app/shared/impersonate/components/ExitImpersonation';
import {AuthService} from '../../../../authentication/service';
import {UserRoles} from '../../../../enum/UserRoles';
import {IUserNotification} from '../../../../model/campaign/Campaign';
import {redDotsSlice} from '../../../../store/influencer/redDotsSlice';
import {IAllStates} from '../../../../store/rootReducer';
import {ErrorToast, SuccessToast} from '../../../../utils/toasters';
import NotificationItem from '../../../components/alert/NotificationItem';
import {CustomImageHandler} from '../../../components/CustomImageHandler';
import {getPackageColor} from '../../../functions/Functions';
import usePagination from '../../../hooks/usePagination';
import ProfileMenu from '../menu/ProfileMenu';
import {SideOverlay} from '../SideOverlay';


const Header = ({isProfileSideBarOpen, setProfileSideBar}: any) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [showBriefingModal, setShowBriefingModal] = useState(false);
    const [isNotificationsOpened, setIsNotificationsOpened] = useState(false);
    const {redDots} = useSelector((state: IAllStates) => state.redDots);
    const {
        user,
    } = useSelector((states: IAllStates) => states.auth);
    const [notificationList, setNotificationList] = useState<IUserNotification[]>([])
    const {t} = useTranslation();
    const location = useLocation();
    const {filterParams, setFilterParams} = usePagination({perPage: 50})
    const isBriefingOpen = location?.pathname?.includes('overview') || location?.pathname?.includes('step');
    const packageBorder = user.userType === UserRoles.INFLUENCER ? '' : `border border-2 border-${getPackageColor(user?.company?.package?.name)}`;

    const openProfile = () => {
        setProfileSideBar(!isProfileSideBarOpen);
    };
    const closeModal = () => setShowBriefingModal(false);
    const openModal = () => setShowBriefingModal(true);
    const markAllAsRead = async () => {
        await AuthService.markAllNotification()
            .then(response => {
                if (response) {
                    SuccessToast(t('general.toasts.successUpdated'));
                    dispatch(redDotsSlice.actions.setRedDots({
                        ...redDots,
                        notifications: 0
                    }))
                }
            }).catch(err => ErrorToast(err));
    }
    useEffect(() => {
        console.log(filterParams, 'filterParams', isNotificationsOpened, 'aaa')
        isNotificationsOpened && AuthService.getNotifications(filterParams)
            .then(response => {
                setNotificationList(response?.data)
            }).catch(err => ErrorToast(err))
    }, [isNotificationsOpened, filterParams]);

    useEffect(() => {
        if (redDots?.notifications) {
            setFilterParams((prev: any) => ({...prev, updatedAt: new Date()?.getTime()}))
        }
    }, [redDots?.notifications])
    return (
        <>
            <CampaignCreateModal show={showBriefingModal} closeModal={closeModal}/>
            <header>
                <Row className="w-100 m-0">
                    <Col lg={5} className="d-flex align-items-center">
                        <Link to="/">
                            <Image
                                alt="Influence vision logo"
                                src="/tempAssets/influence-vision-logo.svg"
                                height={60}
                                width={150}
                            />
                        </Link>
                        {/* <div className="d-flex mr-4">
                            <BreadCrumbs/>
                        </div> */}
                        <ExitImpersonation/>
                    </Col>
                    <Col
                        lg={7}
                        className="d-flex align-items-center justify-content-between justify-content-lg-end ml-auto"
                    >
                        {!(isBriefingOpen || ![UserRoles.BRAND_MASTER, UserRoles.BRAND].includes(user.userType as UserRoles)) &&
                            <Button variant="outline-primary"
                                    className={`mr-4`}
                                    onClick={openModal}
                                    size="sm"
                            >
                                {t('brand.create_new_campaign')}
                            </Button>}
                        {/*<div className="position-relative mr-4" ref={wrapperRef}>*/}

                        {/*    {isNotificationsOpened &&*/}

                        {/*</div>*/}
                        <Dropdown onToggle={(isOpen) => {
                            setIsNotificationsOpened(isOpen);
                        }}>
                            <Dropdown.Toggle id="notification-dropdown" size={'sm'}
                                             className={'bg-transparent p-0 m-0 border-0 mr-4 d-flex'}>
                                <div className="card w-fit-content cursor-pointer">
                                    <div className="card-body p-2">
                                        <BiBell className={isNotificationsOpened ? 'text-primary' : 'text-muted'}
                                                size={20}/>
                                    </div>
                                    {!!redDots?.notifications && <span className={'sidebar-bullet'}>
                                    {redDots?.notifications}
                                </span>}
                                </div>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <div className={'card notification-dropdown bell-list'}>
                                    <div className="card-body p-0 py-2">
                                        {!!notificationList?.length ? notificationList?.map((notification) =>
                                            <Dropdown.Item href={`#`} className={'p-0'}
                                                           key={notification?.id}>
                                                <NotificationItem
                                                    notification={notification}
                                                    isHeader
                                                    setIsNotificationsOpened={setIsNotificationsOpened}
                                                    setFilterParams={setFilterParams}
                                                />
                                            </Dropdown.Item>
                                        ) : <div className={'py-4'}>
                                            <div className="text-center">
                                                <img src="/icons/empty-notification.svg" alt="Empty notification icon"/>
                                                <h6 className="text-muted mb-0 mt-2">No new notifications</h6>
                                            </div>
                                        </div>}
                                        <div
                                            className="d-flex justify-content-between p-2 position-sticky bottom-0 bg-white z-index-1">
                                            <Dropdown.Item href={`#/action-markAsRead`}
                                                           onClick={() => markAllAsRead()}
                                                           className={'p-0 fw-bold'}>
                                                <span className={'font-weight-bold'}>
                                                {t('general.clearAll')}
                                               </span>
                                            </Dropdown.Item>
                                            <Dropdown.Item href={`#/action-seeAll`}

                                                           onClick={() => {
                                                               history.push('/settings/notifications');
                                                               setIsNotificationsOpened(false);
                                                           }}
                                                           className={'p-0 text-right'}>
                                               <span className={'font-weight-bold'}>
                                                    {t('general.seeAllNotifications')}
                                               </span>
                                            </Dropdown.Item>
                                        </div>
                                    </div>
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                        <div className="d-flex flex-lg-column align-items-end mr-3">
                            <div
                                className="mb-0">{[UserRoles.AGENCY, UserRoles.AGENCY_MASTER].includes(user.userType as UserRoles)
                                ? user?.agencyCompany?.displayName : user.company?.displayName}</div>
                            {(user.userType === UserRoles.BRAND_MASTER || user.userType === UserRoles.BRAND ||
                                    user.userType === UserRoles.AGENCY || user.userType === UserRoles.AGENCY_MASTER) &&
                                <div className="d-flex">
                                    {[UserRoles.BRAND_MASTER, UserRoles.AGENCY_MASTER].includes(user.userType) && (
                                        <span className="status pending mr-2 font-weight-normal">ADMIN</span>
                                    )}
                                    <h5 className="mb-0">{user.firstName} {user.lastName}</h5>
                                </div>
                            }
                            {/*<button*/}
                            {/*    className={`btn-sm btn-outline-secondary text-primary p-1 ml-3 ${(user.userType === UserRoles.BRAND
                            || user.userType === UserRoles.BRAND_MASTER) ? '' : 'd-none'}`}>*/}
                            {/*    <Image*/}
                            {/*        className="mr-1"*/}
                            {/*        src="/icons/img-starter-plan.png"*/}
                            {/*        alt="Darts icon"*/}
                            {/*    />*/}
                            {/*    {t('brand.starter_plan')}*/}
                            {/*</button>*/}
                            {user.userType === UserRoles.INFLUENCER &&
                                <>
                                    <h5 className="mb-0">{user.displayName}</h5>
                                    <div className="mb-0">{user.firstName} {user.lastName}</div>
                                </>
                            }
                        </div>
                        <div className="profile-img">
                            <CustomImageHandler
                                altTag="User Profile"
                                thumbnailPath={user?.profilePhoto?.thumbnail}
                                classes={packageBorder}
                                onClick={openProfile}
                                photoPath={`${user.profilePhoto?.path || user.profilePhoto?.thumbnail}`}
                            />
                        </div>
                    </Col>
                </Row>
            </header>
            <header className="mobile">
                <Link to="/">
                    <img width={34} height={34} src={'/icons/logoSmall.svg'} alt="Influence vision logo"/>
                </Link>
                <Button variant="outline-primary"
                        className={`${isBriefingOpen || ![UserRoles.BRAND_MASTER, UserRoles.BRAND].includes(user.userType as UserRoles) ? 'd-none' : null}`}
                        onClick={openModal}
                        size="sm"
                >
                    {t('brand.create_new_campaign')}
                </Button>
                <ExitImpersonation/>
                <div className="profile-img">
                    <CustomImageHandler
                        altTag="User Profile"
                        onClick={openProfile}
                        classes={packageBorder}
                        photoPath={user.profilePhoto?.path as string}
                    />
                </div>
            </header>
            <SideOverlay
                isOpen={isProfileSideBarOpen}
                side={'right'}
                component={<ProfileMenu toggle={openProfile}/>}
            />
        </>
    );
};

export default Header;
